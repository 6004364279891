<template>

<com-content :inner="true" class="extras" :class="{'is-disabled': disabled}">

	<com-head title="What else do you get?" text="There are some extra feature you get that do not incur additional costs." icon="" />

	<com-extra v-for="extra in extras" :key="extra.id" :item="extra" v-on:click="onExtraClick(extra.id)" :selected="$_.contains(value, extra.id)" />

</com-content>

</template>

<script>

export default {

	props: ['value', 'disabled'],
	
	components: {

		'com-content': () => import('./../common/Content'),
		'com-extra': () => import('./Extra'),
		'com-head': () => import('./Head')

	},

	data: function() {

		return {
			extras: [
				{
					id: 'library',
					name: 'Library',
					icon: 'library',
					text: 'A public site for anyone to browse and filter your collection of games - particularly useful if you run a library at your convention. It integrates with the checkout service to give live information on game availability.',
					fee: 0
				},
				{
					id: 'assistant',
					name: 'Assistant',
					icon: 'assistant',
					text: 'A responsive web app for your staff and volunteers to quickly access and perform important tasks during the convention via mobiles, tablets, and desktops. They can check in badge holders, scan event tickets, checkout games, and more.',
					fee: 0
				}
			]
		}

	},

	methods: {

		onExtraClick: function(id) {

			var value = this.$util.copy(this.value)

			if (this.$_.contains(this.value, id)) {

				value.splice(value.indexOf(id), 1)

			} else {

				value.push(id)

			}

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.extras {
	padding: 0px 0px 40px 0px;
	z-index: 2;
}

.is-mobile .extras {
	padding: 40px 10px 20px 10px;
}

.extras:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #fff transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.extras.is-disabled {
	pointer-events: none;
}

.extras.is-disabled > * {
	opacity: 0.25;
}

</style>